@use '../../scss/mixins/button';

.DefaultLayout {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.DefaultLayout__header {
  flex-grow: 0;
  flex-shrink: 0;
  padding: var(--spacing-gutter);
}

.DefaultLayout__back {
  display: block;
}

.DefaultLayout__title {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.DefaultLayout__content {
  flex: 1;
  padding-left: var(--spacing-gutter);
  padding-right: var(--spacing-gutter);

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .GameView {
    flex: 1 1 100%;
  }
}

.DefaultLayout__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-8) var(--spacing-gutter) var(--spacing-gutter);
  color: var(--color-text-page-secondary);
}

.DefaultLayout__tagline {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.DefaultLayout__links {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}

.DefaultLayout__link {
  @include button.as-link;

  text-decoration: none;
}
