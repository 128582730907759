.PlayerNameBlock {
  display: flex;
  flex-direction: column;
}

.PlayerNameBlock__name-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}

.PlayerNameBlock__heading-text,
.PlayerNameBlock__footing-text {
  font-size: var(--font-size-heading-6);
  color: var(--color-palette-gray-400);
}

.PlayerNameBlock__name {
  font-size: 1.8rem;
  font-weight: bold;
  word-break: break-all;
}

// .PlayerNameBlock__crown {
//   background-color: var(--color-palette-yellow-400);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 2rem;
//   height: 2rem;
//   flex-grow: 0;
//   flex-shrink: 0;
//   border-radius: 1rem;

//   svg {
//     display: block;
//     width: 1.2rem;
//     height: auto;
//     fill: var(--color-palette-white);
//   }
// }

.PlayerNameBlock__details {
  font-size: 1.6rem;
  line-height: 1.375;
}

.PlayerNameBlock__tags {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-half);
  margin-top: var(--spacing-quarter);
}
