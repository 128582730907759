@mixin reset {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  font: inherit;
  color: currentColor;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

@mixin as-link {
  @include reset;

  color: currentColor;

  &:hover {
    text-decoration: underline;
  }
}
