@use '../../scss/mixins/button';

.GameSetupPlayerRow {
  display: flex;
  justify-content: space-between;
}

.GameSetupPlayerRow__button {
  @include button.reset;

  font-size: var(--spacing-3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-6);
  height: var(--spacing-6);
}
