.GameSetupView__players {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}

.GameSetupView__player-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  margin-top: var(--spacing-3);
}

.GameSetupView__player-form__input {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-1);

  .FormGroup {
    flex-grow: 1;
  }

  .Button {
    min-height: 6.4rem;
    min-width: 10rem;
  }
}

.GameSetupView__player-form__error {
  color: red;
}

.GameSetupView__player-form__existing-player-button {
  display: flex;
  align-items: center;
}

.GameSetupView__recent-players {
  margin-top: 2.4rem;
}

.GameSetupView__recent-player-buttons {
  display: flex;
  gap: var(--spacing-2);
}

.GameSetupView__holes {
  display: flex;
  gap: var(--spacing-2);
}
