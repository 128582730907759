* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-page);
  color: var(--color-text-page-primary);
  font-weight: var(--font-weight-page);
  background-color: var(--color-bg-page-primary);
}

html {
  font-size: 62.5%;
}

body {
  font-size: var(--font-size-page);
}

a {
  color: currentColor;
}

input,
button {
  font: inherit;
}

h1, h2, h3, h4, h5, h6 {
  // TODO set spacing-system margins
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
}

.size-h1,
h1 {
  font-size: var(--font-size-heading-1);
  font-weight: var(--font-weight-heading);
}

.size-h2,
h2 {
  font-size: var(--font-size-heading-2);
  font-weight: var(--font-weight-heading);
}

.size-h3,
h3 {
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-heading);
}

.size-h4,
h4 {
  font-size: var(--font-size-heading-4);
  font-weight: var(--font-weight-heading);
}

.size-h6,
h6 {
  font-size: var(--font-size-heading-6);
  font-weight: var(--font-weight-heading);
}

.mt-0 {
  margin-top: 0;
}

section {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  padding-left: var(--spacing-4);
}

li {
  margin: 0.2em 0;
}

input {
  font-size: var(--font-size-page);
}
