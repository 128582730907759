.TextInput {
  display: block;
  background-color: var(--color-palette-green-100);
  border: none;
  font-size: 2.4rem; // TODO var
  padding: 16px 24px; // TODO var
  border-radius: var(--radii-button);
  min-height: 6.4rem;

  &::placeholder {
    color: var(--color-palette-green-700);
    opacity: 0.5;
  }
}
