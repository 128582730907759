.GameList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.GameList__row {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}

.GameList__header {
  display: flex;
  align-items: center;
  gap: var(--spacing-half);

  &::after {
    content: '';
    width: 100%;
    height: 0.1rem;
    background-color: var(--color-palette-gray-100);
    flex-grow: 1;
  }
}

.GameList__date {
  font-size: 1.2rem;
  flex-shrink: 0;
  color: var(--color-palette-gray-400);
}

.GameList__item__meta {
  color: var(--color-text-page-secondary);
}

.GameList__players {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
}


