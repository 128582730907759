.LoginView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.LoginView form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  border-bottom: 1px solid var(--color-palette-gray-300);
}

.LoginView .TextInput {
  width: calc(100% - var(--spacing-1) * 2);
  max-width: 420px; // blaaaaze
  margin-bottom: var(--spacing-3);
}

.LoginView__error {
  color: var(--color-palette-red-400);
}
