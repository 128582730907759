.Keyboard {
  padding: 8px;
  background-color: #e2e2e2;
}

.Keyboard__keys {
  display: none;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 8px;

  .Keyboard--visible & {
    display: grid;
  }
}
