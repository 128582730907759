.PlayerTag {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-half);
  background-color: var(--color-palette-gray-100);
  color: var(--color-palette-gray-700);
  font-size: var(--font-size-heading-6);
  font-weight: bold;
  padding: var(--spacing-px) var(--spacing-1);
  border-radius: var(--radii-full);

  &.PlayerTag--crown {
    background-color: var(--color-palette-yellow-400);
    color: var(--color-palette-white);
  }
}
