.FormGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}

.FormGroup__label {
  display: block;
  font-size: var(--font-size-heading-6);
  color: var(--color-palette-gray-400);
}

.FormGroup__content {
  .TextInput {
    width: 100%;
  }
}
