@use '../../scss/mixins/button';

.Header {
  background-color: var(--color-background-header);
  color: var(--color-text-header);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

// .Header__menu {}

.Header__menu-button {
  @include button.reset;
}
