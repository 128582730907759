@use '../../scss/mixins/button';

.Button {
  @include button.reset;

  position: relative;

  .ScrollXRow & {
    flex-shrink: 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--radii-button);
    transition: transform 0.1s ease;
  }

  &:not([disabled]):hover::before {
    transform: scale(1.05);
  }

  &.Button--primary {
    color: var(--color-text-button-primary);

    &::before {
      background-color: var(--color-bg-button-primary);
    }
  }

  &.Button--secondary {
    &::before {
      background-color: var(--color-bg-button-secondary);
    }
  }

  &.Button--transparent {
    padding: 0 !important;
    min-height: auto !important;

    &::before {
      background-color: var(--color-bg-button-transparent);
    }
  }

  &.Button--small {
    min-height: var(--spacing-4);
    font-size: var(--font-size-button-small);
    padding: var(--spacing-half) var(--spacing-2);
  }

  &.Button--medium {
    min-height: var(--spacing-6);
    font-size: var(--font-size-button-medium);
    padding: var(--spacing-1) var(--spacing-2);
    gap: var(--spacing-1);
  }

  &.Button--large {
    min-height: var(--spacing-8);
    font-size: var(--font-size-button-large);
    padding: var(--spacing-2) var(--spacing-4);
    gap: var(--spacing-1);
  }
}

.Button__content {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  position: relative;
  gap: 0.25em;
}
