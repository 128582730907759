:root {
  // Color system
  --color-palette-green-100: #ECF8F1;
  --color-palette-green-200: #B4E4C8;
  --color-palette-green-300: #9EDCB8;
  --color-palette-green-400: #58C181;
  --color-palette-green-500: #35925C;
  --color-palette-green-600: #297147;
  --color-palette-green-700: #235E3A;

  --color-palette-gray-100: #E4E7E5;
  --color-palette-gray-200: #C2C7C4;
  --color-palette-gray-300: #A4ACA8;
  --color-palette-gray-400: #8D9791;
  --color-palette-gray-500: #7C8881;
  --color-palette-gray-600: #5C6560;
  --color-palette-gray-700: #3A403D;

  --color-palette-white: #ffffff;
  --color-palette-yellow-400: #F3CD45;
  --color-palette-red-400: hsl(352 46 48);

  --color-bg-page-primary: var(--color-palette-white);
  --color-text-page-primary: var(--color-palette-green-700);
  --color-text-page-secondary: var(--color-palette-gray-400);

  // Type/font system
  --font-size-heading-1: 5rem;
  --font-size-heading-2: 3rem;
  --font-size-heading-3: 2.8rem;
  --font-size-heading-4: 2.2rem;
  --font-size-heading-5: 1.6rem;
  --font-size-heading-6: 1.4rem;
  --font-weight-heading: bold;
  --font-weight-page: normal;
  --font-family-page: ui-rounded, -apple-system, 'SF Pro Rounded', sans-serif;
  --font-size-page: 1.6rem;

  // Spacing system
  --spacing-px: 0.1rem;
  --spacing-quarter: 0.2rem;
  --spacing-half: 0.4rem;
  --spacing-1:  0.8rem;
  --spacing-2: 1.6rem;
  --spacing-3: 2.4rem;
  --spacing-4: 3.2rem;
  --spacing-5: 4rem;
  --spacing-6: 4.8rem;
  --spacing-7: 5.6rem;
  --spacing-8: 6.4rem;
  --spacing-9: 7.2rem;
  --spacing-10: 8rem;
  --spacing-gutter: var(--spacing-3);

  --radii-full: 1000rem;
  --radii-1: 0.4rem;
  --radii-2: 0.8rem;
  --radii-3: 1.6rem;

  // Buttons
  --font-size-button-small: 1.6rem;
  --font-size-button-medium: 2.4rem;
  --font-size-button-large: 3rem;
  --color-bg-button-primary: var(--color-palette-green-700);
  --color-bg-button-secondary: var(--color-palette-green-100);
  --color-bg-button-transparent: transparent;
  --color-text-button-primary: var(--color-palette-white);
  --radii-button: var(--radii-3);
}
