.ScoreView {
  --rowHeight: 80px;

  display: grid;
  grid-template-columns: minmax(max-content, auto) 1fr;

  &.ScoreView--collapsed-sidebar {
    .PlayerTag__label {
      display: none;
    }
  }

  .GameView & {
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: auto;
    overscroll-behavior-x: none;
  }
}

.ScoreView__collapse-button {
  .Button {
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
  }
}

.ScoreView__players {
  display: grid;
  grid-template-rows: 40px repeat(var(--ScoreView-rows), var(--rowHeight));
  position: sticky;
  left: 0;
  border-right: solid 0.2rem var(--color-palette-gray-100);
  background-color: var(--color-palette-white);
  z-index: 2;
}

.ScoreView__scores {
  display: grid;
  grid-template-columns: repeat(var(--ScoreView-rounds), 1fr);
  grid-template-rows: 40px repeat(var(--ScoreView-rows), var(--rowHeight));
  z-index: 1;
}

.ScoreView__cell {
  padding: var(--spacing-1);
  display: flex;
  align-items: center;

  &.ScoreView__cell--current {
    background-color: var(--color-palette-green-100);
  }

  &.ScoreView__cell--header {
    justify-content: space-between;
    gap: var(--spacing-1);
    border-bottom: solid 0.2rem var(--color-palette-gray-100);

    .ScoreView__scores & {
      justify-content: center;
    }

    &.ScoreView__cell--current {
      border-top-left-radius: var(--radii-2);
      border-top-right-radius: var(--radii-2);
    }
  }

  .ScoreView__players & {
    padding-left: 0;
  }
}
