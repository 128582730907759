.ScoreCell {
  position: relative;
  height: 100%;
}

.ScoreCell__input {
  border: solid 0.2rem var(--color-palette-gray-100);
  border-radius: var(--radii-2);
  padding: var(--spacing-half);
  padding-bottom: 2rem;
  font-size: 1.8rem;
  width: 6rem;
  height: 100%;
  text-align: center;
  font-family: monospace;
  background-color: var(--color-palette-white);

  &:focus-within {
    border-color: var(--color-palette-green-700);
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    display: none;
  }

  &:invalid {
    background-color: salmon;
  }
}

.ScoreCell__subtotal {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0.4rem;
  left: 0;
  font-size: 1.2rem;
  pointer-events: none;
}
