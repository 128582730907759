.ScrollXRow {
  margin-left: calc(var(--spacing-gutter) * -1);
  margin-right: calc(var(--spacing-gutter) * -1);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.ScrollXRow__inner {
  padding-left: var(--spacing-gutter);
  padding-right: var(--spacing-gutter);

  &::after {
    content: '';
    display: block;
  }
}
